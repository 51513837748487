import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import AnimateHeight from "react-animate-height"

import FloatingButton from "../../../_shared/FloatingButton"

//------------------------------------------------------------------------------

function FAQCard(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { question, answer } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  const [isCollapsed, setIsCollapsed] = useState(false)

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function handleFAQCollapse() {
    if (isCollapsed) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const btnIcon = isCollapsed
    ? require("../../../../images/_shared/icons/less-icn.svg")
    : require("../../../../images/_shared/icons/plus-icn.svg")

  return (
    <div className={props.className}>
      <h2>{question}</h2>

      <AnimateHeight duration={300} height={isCollapsed ? "auto" : 0}>
        <p>{answer}</p>
      </AnimateHeight>

      <FloatingButton
        className="floating-btn"
        onClick={handleFAQCollapse}
        icon={btnIcon}
      />
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

FAQCard.propTypes = {
  className: PropTypes.string.isRequired,
  question: PropTypes.string,
  answer: PropTypes.string,
}

FAQCard.defaultProps = {
  question: "",
  answer: "",
}

// ----------------------------------------------------------------------------

const StyledFAQCard = styled(FAQCard)`
  & {
    box-sizing: border-box;
    padding: 19.3px 28px;
    border: 1px solid ${props => props.theme.ashGrey};
    border-radius: 4px;
    width: 310px;
    max-width: 310px;

    height: auto;
    transition: all 0.1s ease-in-out;

    h2 {
      font-weight: normal;
      color: ${props => props.theme.primaryTextColor};
    }

    p {
      box-sizing: border-box;
      padding-top: 16.7px;
      font-size: 18px;
      line-height: 24px;
      color: ${props => props.theme.primaryTextColor};
      overflow-y: hidden;
    }

    .floating-btn {
      margin-top: 11px;
    }

    :hover {
      background-color: ${props => props.theme.pearlAqua};
      border-color: transparent;
      box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.13);

      .floating-btn {
        background-color: ${props => props.theme.white};
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledFAQCard
