import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import Masonry from "react-masonry-component"

import RevealOnScroll from "../../_shared/RevealOnScroll"

import SearchBar from "../../_shared/SearchBar"
import FAQCard from "./FAQCard"

// ----------------------------------------------------------------------------

function FAQSection(props) {
  // -------------------------------------
  // Props destructuring
  // -------------------------------------

  const { faqList, onSearch } = props

  // -------------------------------------
  // Hooks (e.g. useState, useMemo ...)
  // -------------------------------------

  // -------------------------------------
  // Effects
  // -------------------------------------

  // -------------------------------------
  // Component functions
  // -------------------------------------

  function isMobile() {
    const { theme } = props

    if (typeof window !== "undefined") {
      const mediaQuery = theme.mediaQueries && theme.mediaQueries.mobile

      const matchMediaString = `(max-width: ${mediaQuery})`

      const matchMedia = window.matchMedia(matchMediaString)

      if (matchMedia.matches) {
        return true
      }
    }

    return false
  }

  // -------------------------------------

  function renderFAQCards() {
    return (
      faqList &&
      faqList.map(faq => {
        return (
          <RevealOnScroll key={faq.question}>
            <FAQCard
              className="faq-card"
              question={faq.question}
              answer={faq.answer}
            />
          </RevealOnScroll>
        )
      })
    )
  }

  // -------------------------------------
  // Component local variables
  // -------------------------------------

  const masonryOptions = {
    transitionDuration: "0",
    columnWidth: 310,
    gutter: isMobile() ? 0 : 14,
    horizontalOrder: true,
  }

  return (
    <div className={props.className}>
      <SearchBar className="search-bar" onSearch={onSearch} />

      <Masonry
        className={"faq-grid"}
        options={masonryOptions}
        disableImagesLoaded={false}
        updateOnEachImageLoad={false}
        imagesLoadedOptions={{}}
        enableResizableChildren
      >
        {renderFAQCards()}
      </Masonry>
    </div>
  )
}

// ----------------------------------------------------------------------------
// Component PropTypes and default props
// ----------------------------------------------------------------------------

FAQSection.propTypes = {
  className: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  faqList: PropTypes.array,
  onSearch: PropTypes.func,
}

FAQSection.defaultProps = {
  faqList: [],
  onSearch: () => {},
}

// ----------------------------------------------------------------------------

const FAQSectionWithTheme = withTheme(FAQSection)

// ----------------------------------------------------------------------------

const StyledFAQSection = styled(FAQSectionWithTheme)`
  & {
    margin-bottom: 78.5px;

    .faq-grid {
      margin: auto;
      margin-top: 44px;
      width: 958px !important;
      max-width: 958px;
    }

    .search-bar {
      width: 100vw;
    }

    .faq-card {
      margin-bottom: 14px;
    }

    /* MOBILE */

    @media (max-width: ${props => props.theme.mediaQueries.mobile}) {
      display: flex;
      flex-direction: column;
      align-items: center;

      .faq-grid {
        width: 100vw !important;
        max-width: 100vw;
        height: auto !important;
        overflow: hidden;

        > div {
          position: relative !important;
          top: unset !important;
          left: unset !important;

          display: flex;
          flex-direction: row;
          justify-content: center;
        }
      }
    }
  }
`
// ----------------------------------------------------------------------------

export default StyledFAQSection
