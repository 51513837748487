/* global process */
import Prismic from "prismic-javascript"
import { RichText } from "prismic-reactjs"

// ----------------------------------------------------------------------------

const PRISMIC_REPO_NAME = process.env.PRISMIC_REPO_NAME
const PRISMIC_API_ENDPOINT = `https://${PRISMIC_REPO_NAME}.prismic.io/api/v2`
const DOCUMENT_TYPE = "faq"

const PRISMIC_REPO_API_KEY = process.env.API_KEY

// ----------------------------------------------------------------------------

function getBaseURL() {
  return PRISMIC_API_ENDPOINT
}

function getAccessToken() {
  return PRISMIC_REPO_API_KEY
}

// ----------------------------------------------------------------------------

export function getFilteredFAQs(searchString, page = 1) {
  const apiEndpoint = getBaseURL()
  const accessToken = getAccessToken()

  const pageSize = 30

  return Prismic.api(apiEndpoint, { accessToken }).then(api => {
    return api
      .query(
        [
          Prismic.Predicates.at("document.type", DOCUMENT_TYPE),
          Prismic.Predicates.fulltext("document", searchString),
        ],
        {
          orderings: ["[document.first_publication_date]"],
          pageSize,
          page,
        }
      )
      .then(async response => {
        const faqs = (response && response.results) || []

        return faqs.map(prismicItem => {
          return {
            question:
              (prismicItem &&
                prismicItem.data &&
                prismicItem.data.question &&
                RichText.asText(prismicItem.data.question)) ||
              "",
            answer:
              (prismicItem &&
                prismicItem.data &&
                prismicItem.data.answer &&
                RichText.asText(prismicItem.data.answer)) ||
              "",
          }
        })
      })
  })
}
